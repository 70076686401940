import React from 'react';
import css from './RenderTrustBadge.module.css';
import classNames from 'classnames';

const RenderTrustBadge = () => {
  return (
    <div className={css.brandpushTrustBadge}>
      <div className={css.brandpushTitleContainer}>
        <span className={css.brandpushTitle}>AS SEEN ON</span>
      </div>
      <div className={css.brandpushLogoContainer}>
        <img
          className={css.brandpushLeaf}
          alt="Trust Reef"
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"
        />
        <div className={css.brandpushLogoContainerItem}>
          <a
            href="https://markets.businessinsider.com/news/stocks/bigloads-com-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure-1034191910"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Featured on Business Insider"
              className={css.brandpushNewsLogo}
              src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/ea272993-a87c-4e0e-48bd-6ca4d5b1ea00/public"
            />
          </a>
          <a
            href="https://apnews.com/press-release/marketersmedia/bigloads-com-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure-e4260863967959ab439a8a0dc6d9bff8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Featured on Associated Press"
              className={css.brandpushNewsLogo}
              src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/882fcdbd-c12d-4730-abbb-e3e6c2951d00/public"
            />
          </a>
          <a
            href="https://www.theglobeandmail.com/investing/markets/markets-news/Plentisoft/30295965/bigloadscom-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Featured on The Globe And Mail"
              className={css.brandpushNewsLogo}
              src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/7acfc7a6-2b16-4ba6-01d5-96b99d462f00/public"
            />
          </a>
          <a
            href="https://www.streetinsider.com/Evertise+Financial/BigLoads.com+Enhances+Trailer+Rental+Experience+with+Zero+Fees+and+Increased+Local+Exposure/24191037.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Street Insider"
              className={css.brandpushNewsLogo}
              src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/a19f9a6e-32d1-4386-6cff-29d3705e4400/public"
            />
          </a>
          <a
            href="https://www.digitaljournal.com/pr/news/vehement-media/bigloads-com-enhances-trailer-rental-experience-1182153973.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Featured on Digital Journal"
              className={css.brandpushNewsLogo}
              src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/8baa0efa-aec5-4538-f2ad-614438078c00/public"
            />
          </a>
        </div>
        <img
          className={classNames(css.brandpushLeaf, css.mirrored)}
          alt="Trust Reef"
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"
        />
      </div>
      <div className={css.brandpushFooter}>
        <img
          className={css.brandpushWerifiedIcon}
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/5e5b9b47-6288-4640-2b2a-cd2459605c00/public"
          alt="Verified"
        />
        <span>
          Verified by{' '}
          <a href="https://www.brandpush.co" target="_blank" rel="noopener noreferrer">
            BrandPush.co
          </a>
        </span>
      </div>
    </div>
  );
};

export default RenderTrustBadge;
