import React from 'react'
import RenderTrustBadge from './RenderTrustBadge'
import css from './CustomPageBuilder.module.css'

function CustomPageBuilder() {
  return (
    <div className={css.container}><RenderTrustBadge/></div>
  )
}

export default CustomPageBuilder